import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import '../index.scss'

const isProd = process.env.NODE_ENV === 'production'
const basePath = isProd ? process.env.NEXT_PUBLIC_BASE_PATH || '' : ''

import { Hero, Header } from '@cms/ui'

const menuItems = [
  ['ABOUT', '/about'],
  ['WORKS', '/'],
  ['PUBLICATIONS', '/publications'],
  ['NEWS', '/news'],
  ['CONTACT', '/contact'],
]

const getHeroImageType = (loading, pathname) => {
  switch (true) {
    case loading:
      return undefined
    case ['/', '/news', '/exhibitions'].includes(pathname):
      return 'regular-hero-image'
    case ['/contact'].includes(pathname):
      return 'small-hero-image'
    case pathname.includes('/works/'):
    case pathname.includes('/publications/'): 
    case pathname.includes('/series/'):
      return 'no-padding'
    default:
      return undefined
  }
}

const blurHeroImage =
  'data:image/jpg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAASACADASIAAhEBAxEB/8QAGgAAAgIDAAAAAAAAAAAAAAAAAAYCBQMEB//EACQQAAICAQQCAQUAAAAAAAAAAAECAwQABQYRIhJBMRQhI1HB/8QAGQEAAQUAAAAAAAAAAAAAAAAAAgABAwQF/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhEDEQA/AGzds0NDThR05SZJ28ZGH9yp5p0/p6McKyykAMG94y29GjvSLOpKMT2zBbo0ltoTE0ksXw2ZsXaVNw6fX0l4LUcaxuTwy/rN/SpqFqIOGeEkdiPeK+9rmo3rjwLXZF8+jfPJyK7a3OlMSV+3kOfFRhhdfQ/iyNVQXBIBPOGGRiqouRo2p9kU9vYxxrgLpp8Rx9vWGGPSf//Z'

/**
 * Theme is the root React component of our theme. The one we will export
 * in roots.
 */
const App = ({ Component, pageProps }) => {
  // const pageTitle = `Official Site | ${title}`
  const { pathname } = useRouter()

  const loading = pageProps?.fallback

  const heroImageType = getHeroImageType(loading, pathname)

  const currentYear = new Date().getFullYear().toString()

  return (
    <>
      {/* Add some global styles for the whole site, like body or a's. 
      Not classes here because we use CSS-in-JS. Only global HTML tags. */}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1.0"
        ></meta>
      </Head>
      {!!heroImageType && (
        <Hero
          src={'/background'}
          placeholder={blurHeroImage}
          deviceSizes={[
            300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600, 1920,
          ]}
        />
      )}

      {/* Add the header of the site. */}
      <Header items={menuItems} logo={basePath + '/logo.svg'} />
      {/* Add the main section. It renders a different component depending
      on the type of URL we are in. */}
      <main id="main-content" className={heroImageType}>
        <div className="container">
          <Component {...pageProps} />
        </div>
      </main>
      <div className="container">
        <div className="row">
          <div className="footer">{`© ${currentYear} by Chan Kwan-Lok.`}</div>
        </div>
      </div>
    </>
  )
}

export default App
